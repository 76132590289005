/* 
* Animations
*/

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.blink {
  animation: blink 1s linear infinite;
}

.loader {
  animation: rotateAnimation 1.5s infinite linear;
  /* Adjust duration and timing function as needed */
}

@keyframes rotateAnimation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
